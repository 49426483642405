@import url('https://unpkg.com/leaflet@1.7.1/dist/leaflet.css');

.leaflet-default-icon-path {
  background-image: url(https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png);
}

.top-banner {
  background-color: white;
  height: 50px; /* adjust as needed */
  display: flex;
  position: fixed;
  z-index: 1000; /* to ensure the banner appears on top of the map */
  width:100%;
}

.banner-content {
  display: flex;
  width: 100%;
  max-width: 1200px; /* adjust as needed */
  padding: 10px 20px;
}

.brand-name {
  font-size: 24px; /* adjust as needed */
  font-weight: bold;
  color: black;
  width:20%;
}


/* src/App.css */
.bubble-container {
    height: 30px;
    display: flex;
    position: relative;
    z-index: 1000;
    margin:auto;
  }
  
  .bubble {
    background-color: #fff;
    border-radius: 50px;
    padding: 5px 15px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .bubble.selected {
    background-color: blue;
    color: white;
  }
  

  .leaflet-tooltip{
    pointer-events: all;
    cursor: pointer;
  }

  .leaflet-top .leaflet-control {
    margin-top: 60px;
}